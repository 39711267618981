<template>
  <v-app>
    <!-- <AsideMenu v-if="$route.name != 'login' && $store.getters.loggedin"></AsideMenu>
    <AppHeader v-if="$route.name != 'login' && $store.getters.loggedin">></AppHeader> -->

    <AsideMenu v-if="$route.name != 'login'"></AsideMenu>
    <AppHeader v-if="$route.name != 'login'">></AppHeader>
    <v-main>
      <router-view />
      <v-snackbar
        timeout="6000000"
        width="auto"
        right
        v-model="$store.state.connection_snack"
      >
        لايوجد اتصال بالأنترنت
        <template v-slot:action="{ attrs }">
          <v-icon color="white" v-bind="attrs">wifi_off</v-icon>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>
<style scoped></style>
<script>
const AppHeader = () => import("./layout/header.vue");
const AsideMenu = () => import("./layout/AsideMenu.vue");

export default {
  name: "App",
  data: () => ({
    page_keys: {
      pages: [],
    },
  }),
  watch: {
    $route(route) {
      if (route.name == "product" || route.name == "category") {
        console.log(route.name);
        this.$store.dispatch("get_lang");
      }
      if (route.name != "live") {
        window.clearInterval(this.$store.state.live_interval);
      }
    },
  },
  components: {
    AppHeader,
    AsideMenu,
  },
  mounted() {
    if (this.$store.getters.langs.length == 0) {
      this.$store.dispatch("get_lang");
    }
  },
};
</script>
