import axios from "axios";
import VueAxios from "vue-axios";
import Vue from "vue";
import Vuex from "vuex";
Vue.use(VueAxios, axios);
Vue.use(Vuex);

import { EncryptStorage } from "encrypt-storage";
export const encryptStorage = new EncryptStorage("secret-key-value", {
  prefix: "@menu_dash",
  encAlgorithm: "Rabbit",
});

export default new Vuex.Store({
  state: {
    connection_snack: false,
    // def_path: "http://127.0.0.1:8000",
    def_path: "https://app.menyo.co",
    footer_props: {
      showFirstLastPage: true,
      "items-per-page-text": "لكل صفحة",
      "items-per-page-options": [10, 15, 30],
    },

    user_menu_dashboard: encryptStorage.getItem("user_menu_dashboard") || {
      store_name: "default_name",
      is_admin: 0,
    },
    token: encryptStorage.getItem("token_menu_dashboard") || null,
    menu: true,
    ar: false,
    languages: [],
    live_interval: null,
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
    permissions(state) {
      let per = [];
      state.user_menu_dashboard.permissions.forEach((e) => {
        per.push(e.name);
      });
      return per.toString();
    },
    user_menu_dashboard(state, user) {
      state.user_menu_dashboard = user;
    },
    lang: (state) => (section) => {
      let lang = state.lang;
      return lang[section] || [];
    },
    public_lang(state) {
      let lang = state.lang;
      return lang["public"] || [];
    },
    langs(state) {
      return state.languages ? state.languages : [];
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
      console.log(token);
    },
    logout(state) {
      state.token = null;
      encryptStorage.removeItem("user_menu_dashboard");
      encryptStorage.removeItem("token_menu_dashboard");
    },
    user_menu_dashboard(state, user) {
      state.user_menu_dashboard = user;
    },
    public__request(state, item) {
      state.pages[item.name] = item.data;
    },
    set_langs(state, langs) {
      state.languages = Object.assign([], langs);
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone_number", cred.phone_number);
        formData.append("password", cred.password);
        var config = {
          method: "post",
          url: "login",
          headers: {
            Accept: "application/json",
          },
          data: formData,
        };
        axios(config)
          .then((res) => {
            const token = res.data.data.token;
            const user = res.data.data.user_info;
            encryptStorage.setItem("token_menu_dashboard", token);
            encryptStorage.setItem("user_menu_dashboard", JSON.stringify(user));

            context.commit("login", token);
            context.commit("user_menu_dashboard", user);
            console.log(user);
            resolve(res);
            // console.log(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    get_lang(context) {
      var config = {
        method: "get",
        url: "store_lang",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${context.state.token}`,
        },
      };
      axios(config)
        .then((res) => {
          console.log(res);
          let langs = res.data.data;
          context.commit("set_langs", langs);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise((resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || [],
        };

        axios(config)
          .then((res) => {
            context.state.connection_snack = false;
            resolve(res);
          })
          .catch((err) => {
            if (!err.response) {
              context.state.connection_snack = true;
            } else {
              reject(err);
            }
            if (err.response.status == 401) {
              context.commit("logout");
              router.push("/");
            }
          });
      });
    },
  },
  modules: {},
});
