import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
Vue.use(VueRouter);

// Admin routes
const Stores = () => import("@/views/admin/Stores.vue");
const store_page = () => import("@/views//admin/Store.vue");
const sections = () => import("@/views/admin/Sections.vue");
const deliveryManager = () => import("@/views/admin/delivery_manager.vue");
const deliveries = () => import("@/views/admin/deliveries.vue");
const countries = () => import("@/views/admin/countries.vue");
const cities = () => import("@/views/admin/cities.vue");
const languages = () => import("@/views/admin/languages.vue");
const static_trans = () => import("@/views/admin/Static_translation.vue");
const admin_orders = () => import("@/views/admin/Orders.vue");

// stores routes
const Products = () => import("@/views/stores/Products.vue");
const product = () => import("@/views/stores/product.vue");
const Categories = () => import("@/views/stores/Categories.vue");
const category = () => import("@/views/stores/category.vue");
const notes = () => import("@/views/stores/Notes.vue");
const note = () => import("@/views/stores/note.vue");
const orders = () => import("@/views/stores/Orders.vue");
const live = () => import("@/views/stores/live.vue");
const customerOrders = () => import("@/views/stores/CustomerOrders.vue");
const OrdersStats = () => import("@/views/stores/Orderstats.vue");
const additions = () => import("@/views/stores/Additions.vue");
const addition = () => import("@/views/stores/addition.vue");
const tables = () => import("@/views/stores/Tables.vue");

//public  routes
const PageNotFound = () => import("@/views/PageNotFound.vue");
const login = () => import("@/views/login.vue");
const routes = [
  {
    path: "/Stores",
    name: "Stores",
    component: Stores,
    meta: {
      title: "Stores",
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: "/store/:id?",
    name: "store",
    component: store_page,
    meta: {
      title: "store",
      requiresAuth: true,
      is_admin: true,
    },
  },

  {
    path: "/sections",
    name: "sections",
    component: sections,
    meta: {
      title: "sections",
      requiresAuth: true,
      is_admin: true,
    },
  },

  {
    path: "/deliveryManager",
    name: "deliveryManager",
    component: deliveryManager,
    meta: {
      title: "deliveryManager",
      requiresAuth: true,
      is_admin: true,
    },
  },

  {
    path: "/deliveries",
    name: "deliveries",
    component: deliveries,
    meta: {
      title: "deliveries",
      requiresAuth: true,
      is_admin: true,
    },
  },

  {
    path: "/static_trans",
    name: "static_trans",
    component: static_trans,
    meta: {
      title: "static_trans",
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: "/languages",
    name: "languages",
    component: languages,
    meta: {
      title: "languages",
      requiresAuth: true,
      is_admin: true,
    },
  },

  {
    path: "/countries",
    name: "countries",
    component: countries,
    meta: {
      title: "countries",
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: "/cities",
    name: "cities",
    component: cities,
    meta: {
      title: "cities",
      requiresAuth: true,
      is_admin: true,
    },
  },

  {
    path: "/a_orders",
    name: "orders",
    component: admin_orders,
    meta: {
      title: "Orders",
      requiresAuth: true,
      is_admin: true,
    },
  },
  //------ store routes

  {
    path: "/Product/:id?",
    name: "product",
    component: product,
    meta: {
      title: "product",
      requiresAuth: true,
      is_admin: false,
    },
  },
  {
    path: "/Category/:id?",
    name: "category",
    component: category,
    meta: {
      title: "category",
      requiresAuth: true,
      is_admin: false,
    },
  },
  {
    path: "/Categories",
    name: "Categories",
    component: Categories,
    meta: {
      title: "Categories",
      requiresAuth: true,
      is_admin: false,
    },
  },
  {
    path: "/Products",
    name: "Products",
    component: Products,
    meta: {
      title: "Products",
      requiresAuth: true,
      is_admin: false,
    },
  },

  {
    path: "/notes",
    name: "notes",
    component: notes,
    meta: {
      title: "notes",
      requiresAuth: true,
      permission: true,
      perName: "notes",
      is_admin: false,
    },
  },

  {
    path: "/note/:id?",
    name: "note",
    component: note,
    meta: {
      title: "note",
      requiresAuth: true,
      permission: true,
      perName: "notes",
      is_admin: false,
    },
  },
  {
    path: "/additions",
    name: "additions",
    component: additions,
    meta: {
      title: "additions",
      requiresAuth: true,
      permission: true,
      perName: "additions",
      is_admin: false,
    },
  },

  {
    path: "/addition/:id?",
    name: "addition",
    component: addition,
    meta: {
      title: "addition",
      requiresAuth: true,
      permission: true,
      perName: "additions",
      is_admin: false,
    },
  },

  {
    path: "/orders",
    name: "orders",
    component: orders,
    meta: {
      title: "orders",
      requiresAuth: true,
      permission: true,
      perName: "orders",
      is_admin: false,
    },
  },

  {
    path: "/live",
    name: "live",
    component: live,
    meta: {
      title: "live",
      requiresAuth: true,
      permission: true,
      perName: "orders",
      is_admin: false,
    },
  },

  {
    path: "/cube",
    name: "cube",
    component: live,
    meta: {
      title: "cube",
      requiresAuth: true,
      permission: true,
      perName: "orders",
      is_admin: false,
    },
  },

  {
    path: "/customerOrders",
    name: "customerOrders",
    component: customerOrders,
    meta: {
      title: "customerOrders",
      requiresAuth: true,
      permission: true,
      perName: "orders",
      is_admin: false,
    },
  },

  {
    path: "/Stats",
    name: "OrdersStats",
    component: OrdersStats,
    meta: {
      title: "OrdersStats",
      requiresAuth: true,
      permission: true,
      perName: "orders",
      is_admin: false,
    },
  },
  {
    path: "/tables",
    name: "tables",
    component: tables,
    meta: {
      title: "tables",
      permission: true,
      perName: "tables",
      requiresAuth: true,
      is_admin: false,
    },
  },

  // {
  //   path: "/settings",
  //   name: "settings",
  //   component: settings,
  //   meta: {
  //     title: "settings",
  //     requiresAuth: true,
  //   },
  // },

  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },
  {
    path: "*",
    component: PageNotFound,
    meta: {
      title: "Error 404",
    },
  },
  ,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;

  if (to.name == "product" || to.name == "category") {
    store.dispatch("get_lang");
  }
  if (to.name != "live") {
    window.clearInterval(store.state.live_interval);
  }

  if (store.token && store.state.user_menu_dashboard.is_admin == 0) {
    let user = await store.dispatch("public__request", {
      config: {
        url: "store_info",
        method: "get",
      },
    });

    store.commit("user_menu_dashboard", user.data.data);
    localStorage.setItem("user_menu_dashboard", JSON.stringify(user.data.data));
  }

  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next();
    } else {
      if (store.state.user_menu_dashboard.is_admin == 1) {
        next("/stores");
      } else {
        next("/products");
      }
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedin) {
      next({
        name: "login",
      });
    } else {
      if (store.state.user_menu_dashboard.is_admin != 1) {
        if (to.meta.is_admin) {
          next("/products");
        } else {
          if (to.matched.some((record) => record.meta.permission)) {
            if (store.getters.permissions.includes(to.meta.perName)) {
              next();
            } else {
              next("/products");
            }
          } else {
            next();
          }
        }
      } else {
        next();
      }
    }
  }
});
